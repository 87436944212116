<template>
  <div class="top-box">
    <div class="box">
      <div class="box-left" @click="skip">
        <div class="homepage-img"><img src="@/assets/image/homepage.png" alt="" /></div>
        {{ title }}
      </div>
      <div class="box-rigth">
        <div class="right-box">
          <div class="zone-box" v-if="zone">
            <div class="right-zone long" @click="resumePreview(1)">预览简历</div>
            <div class="right-zone long" @click="resumePreview(2)">诊断简历</div>
            <div class="right-zone" @click="resetForm">一键清空</div>
          </div>
          <div class="log-out">
            <div class="profile-picture">
              <img
                :src="userInfo.data.avatar"
                alt=""
                v-if="userInfo && userInfo.data && userInfo.data.avatar"
              />
            </div>
            <div class="out-text" @click="confirmBack">退出</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  props: {
    zone: {
      type: Boolean,
      default: false
    },
    title: {
      type: String
    }
  },
  data() {
    return {}
  },
  computed: {
    ...mapState(['userInfo'])
  },
  methods: {
    ...mapMutations({
      removerContents: 'REMOVER_CONTENTS'
    }),
    //退出登录按钮
    confirmBack() {
      this.removerContents()
      this.$router.push('/resume/login')
    },
    //触发在线编辑的方法 预览和诊断简历
    resumePreview(figure) {
      this.$emit('resumePreview', figure)
    },
    //触发在线编辑的一键清空
    resetForm() {
      this.$emit('resetForm')
    },
    //跳转到首页
    skip() {
      if (this.title !== '首页') {
        this.$router.push('/resume/index')
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.top-box
  width 100%
  height 62px
  background #003DBB
  box-shadow 0px 4px 10px 0px rgba(196, 214, 245, 0.15)
  display flex
  justify-content center
  .box
    width 1200px
    height 100%
    display flex
    justify-content space-between
    align-items center
    .box-left
      display flex
      font-size 28px
      line-height 27px
      font-family Source Han Sans CN
      font-weight 500
      color #FFFFFF
      .homepage-img
        width 30px
        height 30px
        margin-right: 5px
    .box-left:hover
      cursor pointer
    .box-rigth
      .right-box
        display flex
        align-items center
        .log-out
          width 88px
          height 34px
          background #3364c9
          border-radius 4px
          display flex
          align-items center
          margin-left 13px
          .out-text
            font-size 16px
            font-family PingFang SC
            font-weight 500
            color #FFFFFF
          .profile-picture
            width 28px
            height 28px
            margin 0 10px
            img
              width 100%
              height 100%
              border-radius 50%
        .log-out:hover
          cursor pointer
          background #809edd
        .zone-box
          display flex
          .right-zone
            font-size 16px
            font-family PingFang SC
            font-weight 500
            color #FFFFFF
            position relative
            padding-right 14px
            margin-left 14px
          .right-zone.long:after
            content ''
            position absolute
            top 5px
            right 0
            height 14px
            border-right 1px solid #fff
            opacity 0.5
          .right-zone:hover
            cursor pointer
            text-decoration underline
</style>
